<template>
	<div>
		<TopBar></TopBar>
		<!-- <dispTop></dispTop> -->
		<van-row class="L-margin-top-10 L-text-align-center">
			<van-col :span="12" @click="handleChange('disp')" :class="type==='disp' ?' L-maincolor' : 'L-infocolor'">
				<div class="L-margin-top-20">
					<i class="iconfont icon-fayuan1 " style="font-size: 40px;"></i>
				</div>
				<div class="L-margin-top-10 L-text">关于国立</div>
			</van-col>
			<van-col :span="12" @click="handleChange('list')" :class="type==='list' ?' L-maincolor'  : 'L-infocolor'">
				<div class="L-margin-top-20">
					<i class="iconfont icon-lvshi " style="font-size: 40px;"></i>
				</div>
				<div class="L-margin-top-10 L-text">国立公证员</div>
			</van-col>
		</van-row>
		<br>

		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<!-- 简介======================================= -->
		<div class="L-row-95 L-margin-auto-10" v-show="type==='disp'">
			<!-- <div class="L-guoli-title-row "> <span class="L-margin-left-10">吉林省长春市国立公证处简介</span> </div> -->
			<div class="L-text-align-center L-h5 L-maincolor L-margin-top-20"> <b>{{dispObj.deptName}}</b> </div>
			<div class="L-margin-top-20 L-text-align-center">
				<!-- <img src="../assets/img_logo.jpg" style="width: 150px;border-radius: 4px;"> -->
				<img :src="dispObj.logo" style="width: 180px;border-radius: 4px;">
			</div>
			<br />

			<div class="L-text L-infocolor" style="line-height: 22px;">
				<i class="iconfont icon-yinhao"></i>
				{{dispObj.synopsis}}
				<i class="iconfont icon-yinhao-copy-copy"></i>
			</div>
			<!-- <div class="L-margin-top-20 L-text-align-center"> 
                <img src="../assets/img_gzc.png" style="width:90% ;border-radius: 4px;">
            </div> -->
			<br />
		</div>



		<!-- 公正员列表======================================= -->
		<div class="L-row-95 L-margin-auto-10" v-show="type==='list'">
			<van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-for="item in list" :key="item.id"
				@click="$router.push({path:'fairerInfo',query:{...item}})">
				<van-col :span="8" class="L-text-align-center">
					<img :src="item.logo" style="width:80px;">
					<!-- <img src="../assets/img_avatar2.png" style="border:2px solid #fa9061;border-radius: 50%;width:80px;"> -->
				</van-col>
				<van-col :span="12">
					<div class="L-bgtext L-maincolor">
						{{item.user_name}}
						<!-- <span class="L-smtext L-margin-left-10">10年工作经验</span> -->
					</div>
					<div class="L-text L-infocolor L-margin-top-5">{{item.phonenumber}}</div>
					<div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">{{item.honor}}</div>
				</van-col>
				<van-col :span="4" class="L-text-align-center">
					<van-icon name="arrow" class="L-infocolor L-h5" />
				</van-col>
			</van-row>

		</div>

	</div>

</template>
<script>
	import TopBar from "../components/TopBar";
	// import dispTop from "../components/dispTop";

	import {
		notarialOffice,
		notary
	} from "../api"
	export default {
		data() {
			return {
				code: this.$route.query.code || 1,
				dispObj: {},
				type: sessionStorage.getItem("type") || "disp",
				list: [],
			}
		},

		components: {
			TopBar,
			// dispTop
		},
		methods: {
			handleChange(type) {
				sessionStorage.setItem("type", type);
				this.type = type;
			}
		},
		mounted() {

			// this.code = this.$route.query.code || 1;

			notarialOffice({
				code: this.code
			}).then(res => {
				this.dispObj = res.data;
				console.log(res);
			})

			notary({
				code: this.code
			}).then(res => {
				console.log(res.data);
				this.list = res.data;

			})


		}

	}
</script>
<style>

</style>
