<template>
  <div>
    <TopBar>
      <template #left>
        <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
      </template>
    </TopBar>
    <van-form>

      <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]" />
      <van-field v-model="password" type="password" name="新密码" label="新密码" placeholder="新密码"
        :rules="[{ required: true, message: '请填写新密码' }]" />
      <van-field v-model="password" type="password" name="确认密码" label="确认密码" placeholder="确认密码"
        :rules="[{ required: true, message: '请填写确认密码' }]" />
      <div style="margin: 36px;">
        <van-button round block type="info" color="#ec532d" native-type="submit">提交</van-button>
      </div>
    </van-form>
    
  </div>

</template>
<script>
  import TopBar from "../components/TopBar";
  export default {
    data() {
      return {
        password: "",
      }
    },

    components: {
      TopBar
    },
    mounted() {
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
    },

  }

</script>
<style>

</style>