import axios from 'axios'
import {
	Toast,
	Dialog
} from 'vant'
// import store from '@/store'
import {getToken} from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
	baseURL: "http://gz.ywkj.online/gz/",
	// baseURL: "http://192.168.0.152:8088",
	
	// 超时
	timeout: 10000
})
// console.log(process.env.VUE_APP_BASE_API);


// request拦截器
service.interceptors.request.use(config => {
	
	// 是否需要设置 token
	const isToken = (config.headers || {}).isToken === false
	if (getToken() && !isToken) {
		// config.headers['Authorization'] = 'Autogzgj' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	}
	// console.log(config)
	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
	// 未设置状态码则默认成功状态
	const code = res.data.code || 200;
	// 获取错误信息
	const msg = errorCode[code] || res.data.msg || errorCode['default'];
	if (code == 0) {
		console.log(111);

	}

	if (code === 201) {
		Dialog.alert({
			message: res.data.msg,
		}).then(() => {
			window.WeixinJSBridge.invoke('closeWindow', {}, function () { });
		});

	} else if (code === 202) {

		// location.href = '#/siteList';

	} else if (code === 203) {

		// location.href = '#/billDate';

	} else if (code === 500) {

		Toast(msg);
		return Promise.reject(new Error(msg))

	} else if (code !== 200) {

		Toast(msg);
		return Promise.reject('error')

	} else {

		return res.data

	}
},
	error => {
		console.log('err' + error)
		let {
			message
		} = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		Toast.fail(message);

		return Promise.reject(error)
	}
)

export default service
