<template>
    <div>
        <!-- <wx-open-launch-weapp class="openweapp" id="launch-btn" username="gh_48531cfd54fb"
            path="pages/index/index.html">
            <script type="text/wxtag-template">
                <style>.btn { padding: 12px;opacity:1 }</style>
                <button class="btn" id="btn">打开小程序</button>
            </script>
        </wx-open-launch-weapp> -->



    </div>

</template>
<script>
    // import TopBar from "../components/TopBar";
    import wx from 'weixin-js-sdk'
    import { loadUserFace, jsapisign,getFaceUuid } from "../api";


    export default {
        data() {
            return {
                uuid: '',
                openid:this.$route.query.openId,
                unionid:this.$route.query.unionid                
            }
        },



        methods: {
            close() {
                window.WeixinJSBridge.call('closeWindow');
            },
            listen() {
                loadUserFace({ uuid: this.uuid }).then(res => {
                    console.log(res);
                    if (res.code === 200) {
                        if (res.data.type === '0') {
                            clearInterval(this.timer)
                            this.$router.push({
                                path: res.data.callbackurl,
                                query: {
                                    unionid: this.unionid,
                                    openId: this.openid,
                                }
                            });

                        } else if (res.data.type === '1') {
                            clearInterval(this.timer)
                            this.$router.push({
                                path: 'faceFail',
                            })
                        }

                    }
                })

            },
            wxConfig() {

                const url = window.location.href.split('#')[0];
                jsapisign({ url }).then(res => {
                    console.log(res)
                    const { appid, nonce_str, signature, timestamp } = res.data;
                    wx.config({
                        debug: true, // 开发的时候打开调试，配置过程会弹窗提示你配置的情况
                        appId: appid,
                        timestamp: timestamp,
                        nonceStr: nonce_str,
                        signature: signature,
                        jsApiList: [
                            'wx-open-launch-weapp', // 跳转小程序

                        ],
                        openTagList: ['wx-open-launch-weapp']//打开的标签名
                    })
                    wx.ready(function () {
                        console.log('config is success')
                    })
                    wx.error(function (err) {
                        console.log(err)
                    })
                })



            },

            getFaceUuid(){
                const openid = this.openid;
                const unionid = this.unionid;
                getFaceUuid({openid,unionid}).then(res=>{
                    console.log('res',res);
                    if(res.code===200){
                        this.uuid = res.data;
                        this.timer = setInterval(()=>{
                            this.listen()
                        },3000)
                        window.location.href = `http://gz.ywkj.online/gz/GzgjController/formmastervideo?openid=${this.openid}&unionid=${this.unionid}&uuid=${this.uuid}`;
                       
                    }
                    console.log('getFaceUuid',this.uuid);
                    console.log('openid',this.openid);
                    console.log('unionid',this.unionid);
                    
                })

            },


        },
        
        mounted() {
            console.log(111);
            this.getFaceUuid()

            

        }



    }

</script>
<style>
    .codeBtn {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }
</style>