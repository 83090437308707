<template>
	<div>
		<TopBar>
			<!-- <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template> -->
		</TopBar>

		<van-tabs v-model="type" color="#ec532d" @change="handleChange">
			<van-tab title="总部" name="总部"></van-tab>
			<van-tab title="分部" name="分部"></van-tab>

		</van-tabs>
		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<div v-show="type==='总部'">
			<div class="L-row-95 L-margin-auto-10">

				<div class="L-text-align-center">
					<van-icon name="clock" size="30px" color="#F39C12" />
				</div>
				<div class="L-margin-top-10 L-text-align-center L-color-999">工作时间</div>
				<br />
				<div class="L-text L-margin-top-10  L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObj.work_time_zh}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<div class="L-text L-margin-top-10 L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObj.work_time_eh}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<br />
			</div>

			<div style="background-color: #f0f0f0;height: 15px;"></div>
			<div class="L-row-95 L-margin-auto-10">


				<div class="L-text-align-center">
					<van-icon name="location" size="30px" color="#1ABC9C" />
				</div>
				<div class="L-margin-top-10 L-text-align-center L-color-999">办公地址</div>
				<div class="L-text L-margin-top-10  L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObj.address}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<br />
			</div>

			<div style="background-color: #f0f0f0;height: 15px;"></div>
			

			<van-cell-group>
				<van-cell title="联系电话" :value="dispObj.phone" />
				<van-cell title="联系电话2" :value="dispObj.phone2" />
				<van-cell title="联系电话3" :value="dispObj.phone3" />
				<van-cell title="联系电话4" :value="dispObj.phone4" />
			</van-cell-group>




			<div style="background-color: #f0f0f0;height: 15px;"></div>
			<div class="L-row-95 L-margin-auto-10">
				<div class="L-guoli-title-row "> <span class="L-margin-left-10">公交路线</span></div>
				<br />
				<div class="L-text L-infocolor" style="line-height: 22px;">
					{{dispObj.bus_routes}}
					<!-- 乘坐62路、213路、362路、G362路公交车至东朝阳路公交站下车南行170米至国立公证处。乘坐6路、351路、G17路公交车至解放大路公交站下车步行600米至国立公证处。乘坐258路、267路、277路、282路、283路公交车至同志街公交站下车北行780米至国立公证处。轨道交通：乘坐地铁1号线、2号线至解放大路站，出站步行800米至国立公证处。 -->
				</div>

				<br />

			</div>


		</div>
		<div v-show="type==='分部'">
			<div class="L-row-95 L-margin-auto-10">

				<div class="L-text-align-center">
					<van-icon name="clock" size="30px" color="#F39C12" />
				</div>
				<div class="L-margin-top-10 L-text-align-center L-color-999">工作时间</div>
				<br />
				<div class="L-text L-margin-top-10  L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObjFB.work_time_zh}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<div class="L-text L-margin-top-10 L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObjFB.work_time_eh}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<br />
			</div>

			<div style="background-color: #f0f0f0;height: 15px;"></div>
			<div class="L-row-95 L-margin-auto-10">


				<div class="L-text-align-center">
					<van-icon name="location" size="30px" color="#1ABC9C" />
				</div>
				<div class="L-margin-top-10 L-text-align-center L-color-999">办公地址</div>
				<div class="L-text L-margin-top-10  L-infocolor" style="line-height: 22px;">
					<!-- <i class="iconfont icon-yinhao"></i> -->
					{{dispObjFB.address}}
					<!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
				</div>
				<br />
			</div>

			<div style="background-color: #f0f0f0;height: 15px;"></div>


			<van-cell-group>
				<van-cell title="联系电话" :value="dispObjFB.phone" />
				<van-cell title="联系电话2" :value="dispObjFB.phone2" />
				<van-cell title="联系电话3" :value="dispObjFB.phone3" />
				<van-cell title="联系电话4" :value="dispObjFB.phone4" />
			</van-cell-group>




			<div style="background-color: #f0f0f0;height: 15px;"></div>
			<div class="L-row-95 L-margin-auto-10">
				<div class="L-guoli-title-row "> <span class="L-margin-left-10">公交路线</span></div>
				<br />
				<div class="L-text L-infocolor" style="line-height: 22px;">
					{{dispObjFB.bus_routes}}
					<!-- 乘坐62路、213路、362路、G362路公交车至东朝阳路公交站下车南行170米至国立公证处。乘坐6路、351路、G17路公交车至解放大路公交站下车步行600米至国立公证处。乘坐258路、267路、277路、282路、283路公交车至同志街公交站下车北行780米至国立公证处。轨道交通：乘坐地铁1号线、2号线至解放大路站，出站步行800米至国立公证处。 -->
				</div>

				<br />

			</div>


		</div>



	</div>
</template>
<script>
	import TopBar from "../components/TopBar";

	import {
		notarialOffice
	} from "../api"

	export default {
		data() {
			return {
				code: this.$route.query.code || 1,
				dispObj: {},
				dispObjFB: {},
				type: sessionStorage.getItem("jdListType") || "司法鉴定人员",

			}
		},

		components: {
			TopBar,
		},
		methods: {
			handleChange(name) {
				this.type = name;

			},

			getData(code) {
				notarialOffice({
					code: code
				}).then(res => {
					// this.dispObj = res.data;
					console.log(res);
				})
			}

		},
		mounted() {


			notarialOffice({
				code: this.code
			}).then(res => {
				this.dispObj = res.data;
				console.log(res);
			});
			notarialOffice({
				code: 4
			}).then(res => {
				this.dispObjFB = res.data;
				console.log(res);
			})





		}

	}
</script>
<style>

</style>
