<template>
    <div>
        <TopBar>
            
        </TopBar>
        <dispTop></dispTop>

        <br />
        <div style="background-color: #f0f0f0;height: 15px;"></div>



        <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list"
            @click="$router.push({path:'fairerInfo',query:{id:1}})">
            <van-col :span="8" class="L-text-align-center">
                <img src="../assets/img_avatar2.png" style="border:2px solid #fa9061;border-radius: 50%;width:80px;">
            </van-col>
            <van-col :span="12">
                <div class="L-bgtext L-maincolor">
                    李小丽
                    <!-- <span class="L-smtext L-margin-left-10">10年工作经验</span> -->
                </div>
                <div class="L-text L-infocolor L-margin-top-5">15501248457</div>
                <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">擅长:民事纠纷,离婚协议等等....</div>
            </van-col>
            <van-col :span="4" class="L-text-align-center">
                <van-icon name="arrow" class="L-infocolor L-h5" />
            </van-col>
        </van-row>



    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    import dispTop from "../components/dispTop";
    export default {
        data() {
            return {
                type: "律师信息",
            }
        },
        components: {
            TopBar,
            dispTop
        },
        methods: {
            handleChange(name) {
                this.type = name;
            }

        }
    }

</script>
<style>

</style>