<template>
	<div id="app">
		<keep-alive>
			<router-view v-if='$route.meta.keepAlive'></router-view>
		</keep-alive>
		<transition :name="transition">
			<router-view v-if='!$route.meta.keepAlive'></router-view>
		</transition>
	</div>
</template>

<script>
	// import HelloWorld from './components/HelloWorld.vue'

	export default {
		name: 'App',
		data() {
			return {
				transition: "",
			}
		},
		mounted() {

			document.querySelector('body').setAttribute('style', 'background-color:#fff');
		},
		watch: {
			$route(to, from) {
				// console.log(from.meta.keepAlive);
				if (to.meta.index > from.meta.index) {
					this.transition = "slide-left";
				} else {
					this.transition = "slide-right";
				}
			}
		}
	}
</script>

<style>
	.slide-right-enter-active,
	.slide-right-leave-active,
	.slide-left-enter-active,
	.slide-left-leave-active {
		will-change: transform;
		transition: all 300ms;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.slide-right-enter {

		transform: translate3d(-100%, 0, 0);
	}

	.slide-right-leave-active {

		transform: translate3d(100%, 0, 0);
	}

	.slide-left-enter {

		transform: translate3d(100%, 0, 0);
	}

	.slide-left-leave-active {

		transform: translate3d(-100%, 0, 0);
	}
</style>