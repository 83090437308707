import Vue from "vue";
import vuerouter from "vue-router";

//引入组件
import lawyerList from "../view/lawyerList.vue";
import lawyerInfo from "../view/lawyerInfo.vue";
import officeDetail from "../view/officeDetail.vue";
import guoliDisp from "../view/guoliDisp.vue";
import fairerList from "../view/fairerList.vue";
import fairerInfo from "../view/fairerInfo.vue";
import fairProcess from "../view/fairProcess.vue";
import my from "../view/my.vue";
import listen from "../view/listen.vue";
import videoList from "../view/videoList.vue";
import password from "../view/password.vue";
import search from "../view/search.vue";
import searchList from "../view/searchList.vue";
import searchSecondList from "../view/searchSecondList.vue";
import searchDetail from "../view/searchDetail.vue";
import contactUs from "../view/contactUs.vue";
import register from "../view/register.vue";
import registerFail from "../view/registerFail.vue";
import faceFail from "../view/faceFail.vue";
import identifierList from "../view/identifierList.vue";
import identifierInfo from "../view/identifierInfo.vue";
import identificationCenterInfo from "../view/identificationCenterInfo.vue";
import idInput from "../view/idInput.vue";
import qrcode from "../view/qrcode.vue";
import zxlist from "../view/zxlist.vue";
import audioList from "../view/audioList.vue";
import sign from "../view/sign.vue";





Vue.use(vuerouter);

const routes = [
	{
		path: '/',
		redirect: '/lawyerList',
	},
	{
		path: "/lawyerList",
		component: lawyerList,
		meta: {
			title: "律师事务所",
			index: 1,
		},
	},
	{
		path: "/sign",
		component: sign,
		meta: {
			title: "签名",
			index: 1,
		},
	},
	{
		path: "/guoliDisp",
		component: guoliDisp,
		meta: {
			title: "国立简介",
			index: 1,
		},
	},
	{
		path: "/fairerList",
		component: fairerList,
		meta: {
			title: "国立公证员",
			index: 2,
		},
	},
	{
		path: "/fairerInfo",
		component: fairerInfo,
		meta: {
			title: "公证员信息",
			index: 3,
		},
	},
	{
		path: "/fairProcess",
		component: fairProcess,
		meta: {
			title: "公证流程",
			index: 1,
		},
	},
	{
		path: "/lawyerInfo",
		component: lawyerInfo,
		meta: {
			title: "律师信息",
			index: 3,
		},
	},
	{
		path: "/officeDetail",
		component: officeDetail,
		meta: {
			title: "事务所详情",
			index: 2,
		},
	},
	{
		path: "/my",
		component: my,
		meta: {
			title: "我的",
			index: 1,
		},
	},

	{
		path: "/videoList",
		component: videoList,
		meta: {
			title: "我的视频",
			index: 2,
		},
	},
	{
		path: "/password",
		component: password,
		meta: {
			title: "修改密码",
			index: 2,
		},
	},
	{
		path: "/search",
		component: search,
		meta: {
			title: "证明材料查询",
			index: 1,
			keepAlive:true,
		},
	},
	
	{
		path: "/searchList",
		component: searchList,
		meta: {
			title: "证明材料",
			index:2,
			keepAlive:false,
		},
	},
	{
		path: "/searchSecondList",
		component: searchSecondList,
		meta: {
			title: "证明材料",
			index:3,
			keepAlive:false,
		},
	},
	
	{
		path: "/searchDetail",
		component: searchDetail,
		meta: {
			title: "证明材料",
			index:4,
		},
	},
	{
		path: "/contactUs",
		component: contactUs,
		meta: {
			title: "联系我们",
			index:1,
		},
	},

	{
		path: "/identifierList",
		component: identifierList,
		meta: {
			title: "司法鉴定",
			index:1,
		},
	},
	{
		path: "/identifierInfo",
		component: identifierInfo,
		meta: {
			title: "司法鉴定",
			index:2,
		},
	},

	{
		path: "/identificationCenterInfo",
		component: identificationCenterInfo,
		meta: {
			title: "鉴定中心",
			index:2,
		},
	},
	{
		path: "/register",
		component: register,
		meta: {
			title: "注册",
			index:1,
		},
	},
	{
		path: "/registerFail",
		component: registerFail,
		meta: {
			title: "注册失败",
			index:2,
		},
	},
	{
		path: "/faceFail",
		component: faceFail,
		meta: {
			title: "人脸识别失败",
			index:1,
		},
	},
	{
		path: "/idInput",
		component: idInput,
		meta: {
			title: "远程视频",
			index:2,
		},
	},
	{
		path: "/qrcode",
		component: qrcode,
		meta: {
			title: "远程视频",
			index:3,
		},
	},
	{
		path: "/zxlist",
		component: zxlist,
		meta: {
			title: "咨询",
			index:1,
		},
	},
	{
		path: "/audioList",
		component: audioList,
		meta: {
			title: "通话录音",
			index:2,
		},
	},
	{
		path: "/listen",
		component: listen,
		meta: {
			title: "请稍等",
			index: 4,
		},
	},
];
var router = new vuerouter({
	// mode:"history",
	routes,
	// 跳转后回到顶部............
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {
			x: 0,
			y: 0
		}
	}
	//跳转后回到顶部............
});


export default router;
