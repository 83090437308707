<template>
    <div>
        <div class="L-guoli-topbar L-bg-maincolor">
            <van-row type="flex" align="center">
                <van-col :span="4">
                    <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
                </van-col>
                <van-col class="L-text-align-center L-nowrap-row" :span="16">
                    <span class="L-bgtext">{{infoObj.material_code}}.{{infoObj.material_name}}</span>
                </van-col>
                <van-col :span="4" class="L-text-align-right">
                    
                </van-col>
            </van-row>
        </div>
        <div class="L-margin-top-45"></div>
        <br />
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">证明材料</span> </div>
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                {{infoObj.material_prove}}
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">材料来源</span></div>
            <br />
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                {{infoObj.material_source}}
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
           
            <br />

        </div>
        
    </div>
</template>
<script>
    // import TopBar from "../components/TopBar";
    export default {
        data(){
            return{
                infoObj:{}
            }
        },
        // components: {
        //     TopBar,
        // },
        created(){
            this.infoObj = {...this.$route.query}
            console.log(this.infoObj);
        }

    }

</script>
<style>

</style>