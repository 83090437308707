<template>
    <div>
        <TopBar>
            <!-- <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template> -->
        </TopBar>
        <br />
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">受理</span> </div>
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                当事人带好材料清单上的材料前往公证处，公证员予以受理。
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">审查</span></div>
            <br />
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                公证机构受理公证申请后,根据不同公证事项的办证规则，分别审查：<br/>
                （一）当事人的人数、身份、申请办理该项公证的资格及相应的权利；<br/>
                （二）当事人的意思表示是否真实；<br/>
                （三）申请公证的文书的内容是否完备，含义是否清晰，签名、印鉴是否齐全；<br/>
                （四）提供的证明材料是否真实、合法、充分；<br/>
                （五）申请公证的事项是否真实、合法。<br/>
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
           
            <br />

        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">核实</span></div>
            <br />
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                公证机构在审查中，对申请公证的事项以及当事人提供的证明材料，按照有关办证规则需要核实或者对其有疑义的，将进行核实，或者委托异地公证机构代为核实。
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">出具公证书</span></div>
            <br />
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                （一）公证机构经审查，认为申请公证的事项符合《公证法》和《公正程序规则》及有关办证规则规定的，自受理之日起十五个工作日内向当事人出具公证书。<br/>
                （二）因不可抗力、补充证明材料或者需要核实有关情况的，所需时间不计算在前款规定的期限内，并应当及时告知当事人。
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>

    </div>
</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
        // data(){

        // },
        components: {
            TopBar,

        }

    }

</script>
<style>

</style>