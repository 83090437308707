<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <div class="L-guoli-avater-container">
            <div> 
                <!-- <img src="../assets/img_avatar1.png"> -->
                <img :src="infoObj.logo">

            </div>
            <div class="L-margin-top-10">
                <span class="L-bgtext L-maincolor">{{infoObj.user_name}}</span>
            </div>
        </div>
        <div class="L-row-95 L-margin-auto-10">

            <div class="L-guoli-title-row "> <span class="L-margin-left-10">联系电话</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
                {{infoObj.phonenumber}}
                <!-- 15585637814 -->
            </div>
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>

        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">工作及学习经历</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
                <!-- <i class="iconfont icon-yinhao"></i> -->
                <span v-html="infoObj.experience"></span>
                <!-- {{infoObj.experience}} -->

               
                <!-- <i class="iconfont icon-yinhao-copy-copy"></i> -->
            </div>
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">荣誉</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">

                {{infoObj.honor}}

            </div>
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">业务文章</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
                {{infoObj.business_article}}
            </div>
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>





    </div>
</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
        data(){
            return{
                infoObj:{},
            }

        },
        components: {
            TopBar,

        },
        mounted(){
            this.infoObj = {...this.$route.query};
            console.log(this.infoObj);

        }

    }

</script>
<style>

</style>