<template>
	<div>
		<TopBar>
			<template #left>
				<i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
			</template>
		</TopBar>
		<div class="L-guoli-avater-container" style="background: none;">
			<div> <img :src="this.infoObj.logo===null ? avatarDefault : this.infoObj.logo" style="width: 120px;border:none"></div>

		</div>
		<br />
		<div class="L-row-95 L-margin-auto-10">
			<div class="L-guoli-title-row "> <span class="L-margin-left-10">{{infoObj.judicial_appraiser_name}}</span>
			</div>
			<div class="L-text L-infocolor" style="line-height: 22px;">
				<div>{{infoObj.judicial_appraiser_sitename}}</div>

			</div>
			<br />
		</div>
		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<van-cell-group>
			<van-cell title="专业技术职称" :value="infoObj.professional_title" />
			<van-cell title="行业职业资格" :value="infoObj.professional_qualification" />
			<van-cell title="专业技术职称" :value="infoObj.occupation_type" />
			<van-cell title="职业证号" :value="infoObj.occupation_code" />
		</van-cell-group>


		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<div class="L-row-95 L-margin-auto-10">
			<div class="L-guoli-title-row "> <span class="L-margin-left-10">简介</span></div>
			<div class="L-text L-infocolor" style="line-height: 22px;">
				<i class="iconfont icon-yinhao"></i>
				{{infoObj.synopsis}}
				<i class="iconfont icon-yinhao-copy-copy"></i>
			</div>

			<br />
		</div>




	</div>
</template>
<script>
	import TopBar from "../components/TopBar";
	import avatarDefault from "../assets/img_avatar111.jpg"

	export default {
		data() {
			return {
				infoObj: {},
				lawyerList: [],
				avatarDefault: avatarDefault
			}

		},
		components: {
			TopBar,

		},
		created() {
			this.infoObj = {
				...this.$route.query
			}
			console.log(this.infoObj);

		}

	}
</script>
<style>

</style>
