<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <br>
        <div class="L-text-align-center L-margin-top-20">
            <img src="../assets/img_register7.png" style="width: 80%;" />
        </div>
        <br>
        <div class=" L-margin-auto-10">
            <div style="position: relative;">
                <van-field v-model="name" name="姓名" label="姓名" placeholder="姓名" disabled />
                <van-field v-model="certificate_code" name="身份证号" label="身份证号" placeholder="身份证号" disabled />
                <van-field v-model="phone" name="电话号" label="电话号" placeholder="电话号" />
                <van-field v-model="mobilecode" name="验证码" label="验证码" placeholder="验证码"></van-field>
                <div class="codeBtn">
                    <van-button plain type="info" size="mini" color="#fe841b" v-show="!isClick" @click="readSeconds">
                        发送验证码</van-button>
                    <span class="L-text-align-center L-smtext L-infocolor" v-show="isClick">{{seconds}}s 后重发</span>
                </div>
            </div>


            <div style="margin: 16px; margin-top: 40px;">
                <van-button block type="info" color="#ec532d" native-type="submit" @click="registeSubmit">提交</van-button>
            </div>
        </div>

    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    // import {
    //     setToken,
    //     // getToken
    // } from '@/utils/auth';
    import { Mobile_message, user_register,loadUserData} from "../api"
    export default {
        data() {
            return {
                name: "",
                certificate_code: "",
                phone: "",
                mobilecode: "",
                isClick: false,
                seconds: 10,
                int: "",
                openId: '',
                unionid: '',
				type:this.$route.query.type || 0,

            }
        },
        computed: {

        },

        components: {
            TopBar
        },
        methods: {
            readSeconds() {
                const params = {
                    phone: this.phone,
                    openId: this.openId,
                    unionid: this.unionid
                }

                Mobile_message(params).then(res => {
                    console.log(res);
                    

                })
                this.isClick = !this.isClick;
                this.int = setInterval(() => {
                    return this.seconds--;

                }, 1000)
            },
            registeSubmit() {
                const params = {
                    name: this.name,
                    certificate_code: this.certificate_code,
                    phone: this.phone,
                    mobilecode: this.mobilecode,
                    openId: this.openId,
                    unionid: this.unionid

                }
                user_register(params).then(res => {
                    console.log("注册提交", res);
                    if (res.code == 0) {
						const pathname = this.type==1 ? "zxlist" : "my";
                        this.$router.push({
                            path: pathname,
                            query: {
                                openId: this.openId,
                                unionid: this.unionid
                            }
                        });
                    }
                })
            },
		

        },
        mounted() {
            // const { wxtoken, certificate_code, phone, name, openId, unionid } = this.$route.query;
			const {  openid,unionid } = this.$route.query;
			console.log('openid',openid)
			
    
            this.openId = openid;
            this.unionid = unionid;
			console.log(this)
			// user_register()
			loadUserData({unionid:unionid}).then(res=>{
				if(res.code===200){
					this.name = res.data.name;
					this.certificate_code = res.data.certificate_code;				
				}
			})
			
            



            // if (wxtoken) {
            //     setToken(wxtoken);
            // }
            // console.log(getToken(wxtoken))

            // this.name = name;
            // this.certificate_code = certificate_code;
            // this.phone = phone;

        },
        watch: {
            seconds(val) {
                console.log(val);
                if (val <= 0) {
                    this.seconds = 10;
                    this.isClick = !this.isClick;
                    window.clearInterval(this.int);
                }
                // console.log(val,oldval,this.isClick)


            }


        }

    }

</script>
<style>
    .codeBtn {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }
</style>