<template>
	<div>
		<TopBar>
			<template #left>
				<i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
			</template>
		</TopBar>
		<div class="L-guoli-avater-container">
			<div> <img :src="infoObj.logo" style="border: none;"></div>

		</div>
		<div class="L-row-95 L-margin-auto-10">

			<div class="L-guoli-title-row "> <span class="L-margin-left-10">{{infoObj.judicial_name}}</span></div>

			<div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
				<i class="iconfont icon-yinhao"></i>
				{{infoObj.center_synopsis}}
				<i class="iconfont icon-yinhao-copy-copy"></i>
			</div>
			<br />
		</div>


		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<div class="L-row-95 L-margin-auto-10">

			<div class="L-guoli-title-row "> <span class="L-margin-left-10">职业范围</span></div>

			<div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
				<i class="iconfont icon-yinhao"></i>
				{{infoObj.practice_area}}
				<i class="iconfont icon-yinhao-copy-copy"></i>
			</div>
			<br />
		</div>
		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<div class="L-row-95 L-margin-auto-10">
			<div class="L-guoli-title-row "> <span class="L-margin-left-10">专家团队</span></div>
			<br />
			<div class="L-guoli-flex-row">
				<div class="L-guoli-people-box" v-for="item in list" :key="item.id"
					@click="$router.push({path:'identifierInfo',query:{...item}})">
					<div class="img-box">
						<img :src="item.logo===null ? avatarDefault : item.logo" style="width: 140px;">
					</div>
					<div class="L-margin-top-10 L-text L-text-align-center">{{item.judicial_appraiser_name}}</div>
				</div>

			</div>
			<br />

		</div>
		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<div class="L-row-95 L-margin-auto-10">
			<div class="L-guoli-title-row "> <span class="L-margin-left-10">地址</span></div>

			<div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
				{{infoObj.address}}
			</div>

		</div>

		<div style="background-color: #f0f0f0;height: 15px;"></div>
		<van-cell-group>
			<van-cell title="联系电话" :value="infoObj.phone" />
			<van-cell title="座机" :value="infoObj.tel" />
			<van-cell title="邮箱" :value="infoObj.email" />
			<van-cell title="网址" :value="infoObj.website" />
		</van-cell-group>
		<br />





	</div>
</template>
<script>
	import TopBar from "../components/TopBar";
	import avatarDefault from "../assets/img_avatar111.jpg"
	import {
		getJAppraiserByID
	} from "../api/index.js"
	export default {
		data() {
			return {
				infoObj: {},
				list: [],
				avatarDefault:avatarDefault
			}
		},
		components: {
			TopBar,
		},
		created() {
			this.infoObj = {
				...this.$route.query
			}
			// console.log(this.infoObj);
			getJAppraiserByID({
				id: this.infoObj.id
			}).then(res => {
				this.list = res.data;
				console.log(this.list);
			})
		}

	}
</script>
<style>

</style>
