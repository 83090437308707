<template>
    <div>
        <!-- <TopBar></TopBar> -->
        <div class="L-my-top-container">
            <div><img src="../assets/img_avatar11.png" class="my-avatar-img"></div>
            <!-- <div><img :src="avatar" class="my-avatar-img" ></div> -->
            <div class="L-margin-top-10"><span class="L-text L-whitecolor">{{user_name}}</span></div>
        </div>
        <div>
            <!-- <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" 
            @click="$router.push({path:'idinput',query:{openId:openId,unionid:unionid}})" v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-lujing95" style="color:#E67E22"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">远程视频</span></van-col> 
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>               
            </van-row> -->
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="toface"
                v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-lujing95" style="color:#E67E22"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">远程视频</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;"
                @click="$router.push({path:'videoList',query:{openId:openId,unionid:unionid} })" v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-shipin" style="color:#1ABC9C"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">我的视频</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;"
                @click="$router.push({path:'audioList',query:{mobile:mobile} })" v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="service-o" size="20" color="#F39C12" />

                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">通话录音</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <!-- <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building" v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-shipin" style="color:#1ABC9C"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">我的视频</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building" v-if="point==1">
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="service-o" size="20" color="#F39C12" />
                   
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">通话录音</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row> -->

            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="toPhone"
                v-if="point!=1">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-shouji5" style="color:#F1C40F"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">电话咨询</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>

            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-hetong" style="color:#9B59B6"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">我的委托单</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building">
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="clock-o" color="#E74C3C" />
                    <!-- <i class="iconfont icon-nashui L-h4" style="color:#3498DB"></i> -->
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">我的预约</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-jicheng L-h5" style="color:#1ABC9C"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">社会关系</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
            <van-row type="flex" align="center" style="border-bottom:1px solid #eee;padding:15px 0;" @click="building">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-caichan" style="color:#E67E22"></i>
                </van-col>
                <van-col :span="16"><span class="L-bgtext L-color-999">我的财务</span></van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>
        </div>

    </div>
</template>
<script>
    // import TopBar from "../components/TopBar"
    import { setToken } from '@/utils/auth';
    import { getuserurl, getVirtualNumber, jcbd, getStr } from "../api"
    import touxiang from "../assets/img_avatar11.png"
    import { Toast, Dialog } from "vant"
    import wx from 'weixin-js-sdk';
    import {
        getSignature,
        wx_appid,
        wx_noncestr,
        wx_timestamp,
    } from "../utils/function.js";
    import imglink from "../assets/img_logo.jpg";

    export default {
        data() {
            return {
                avatar: touxiang,
                user_name: "用户名",
                mobile: "",
                point: 0,
                openId: this.$route.query.openId,
                unionid: this.$route.query.unionid,

                // appid: "wx2c9327c6798131d5",
                // noncestr: "glz4220969",
                // timestamp: Date.parse(new Date()) / 1000,
                // // url: window.location.href.split("#")[0],
                // url: window.location.href,
                // signature: "",
            }
        },
        components: {
            // TopBar
        },
        methods: {
            toPhone() {
                getVirtualNumber({ tel: this.mobile }).then(res => {
                    console.log(res)

                    if (res.code === 0) {
                        Dialog.alert({
                            title: '提示',
                            message: res.data,
                        })
                    } else {
                        Dialog.confirm({
                            title: '标题',
                            message: '您可以和公证员通话了',
                        }).then(() => {
                            window.location.href = `tel:${res.data}`;
                        }).catch(() => {
                            jcbd().then((res1) => {
                                console.log(res1)
                            })

                        });


                    }
                })


            },
            building() {
                Toast("建设中")
            },
            toface() {
                // this.$router.push({
                //     path: 'listen',
                //     query: {
                        
                //         unionid: this.unionid,
                //         openId: this.openId,
                       
                //     }
                // });


                window.location.href = `http://gz.ywkj.online/gz/GzgjController/getFace?openid=${this.openId}&unionid=${this.unionid}&jumptype=3`;
            }
        },
        mounted() {

            //获取签名
            getStr().then(res => {
                console.log(res.data);
                const jsapi_ticket = res.data;
                let signParams = {
                    jsapi_ticket: jsapi_ticket,
                    noncestr: wx_noncestr,
                    timestamp: wx_timestamp,
                    url: window.location.href,
                }
                const signature = getSignature(signParams);
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: wx_appid, // 必填，公众号的唯一标识
                    timestamp: wx_timestamp, // 必填，生成签名的时间戳
                    nonceStr: wx_noncestr, // 必填，生成签名的随机串
                    signature: signature, // 必填，签名
                    jsApiList: ["updateAppMessageShareData", "hideMenuItems"] // 必填，需要使用的JS接口列表
                });

                wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                    wx.updateAppMessageShareData({
                        title: '同联共证', // 分享标题
                        desc: '同联共证', // 分享描述
                        link: 'http://gz.ywkj.online/gz/GzgjController/gz_loadCode', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imglink, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:openWithQQBrowser"] // 要显示的菜单项，所有menu项见附录3
                    });
                });

            })







            if (this.$route.query.wxtoken) {
                setToken(this.$route.query.wxtoken);
            }


            getuserurl({ openId: this.openId, unionid: this.unionid }).then(res => {
                // console.log("我的", res);
                this.user_name = res.data.user_name;
                this.avatar = res.data.avatar;
                this.point = res.data.point;
                this.mobile = res.data.mobile;
            })

        }
    }
</script>
<style>


</style>