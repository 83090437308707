<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-for="item in list"
            :key="item.file_id">
            <van-col :span="3" class="L-text-align-center">
                <i class="iconfont icon-shipin L-h4 L-maincolor"></i>
            </van-col>
            <van-col :span="14">
                <div class="L-text L-color-777" @click="playVideo(item.video_url)">{{item.gretiem}}-{{item.names}}</div>
            </van-col>
            <van-col :span="7" class="L-text-align-center">

                <i class="iconfont icon-shipin1 L-h5" style="color:#F39C12" @click="playVideo(item.video_url)"></i>
                <!-- <i class="iconfont icon-xiazai11 L-margin-left-10 L-h5" style="color:#1ABC9C"></i> -->
                <i class="iconfont icon-shanchu L-margin-left-10 L-h5" style="color:#BDC3C7"
                    @click="delVideo(item.file_id,item.video_id)"></i>
            </van-col>
        </van-row>

        <div class="L-mask L-text-align-center" v-show="maskShow" style="z-index:10000000;background-color:#000;">
            <i class="iconfont L-whitecolor icon-shanchu11 L-h3" @click="closeVideo"
                style="position: absolute;z-index: 99; top: 10px; right:10px"></i>
            <video :src="videoUrl" controls="controls" width="100%" height="90%" style="margin-top: 45px;">
                your browser does not support the video tag
            </video>
        </div>


    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    import { getvideolist, delvideo,getStr } from "../api";
    import { Dialog, Toast } from 'vant';
    import wx from 'weixin-js-sdk';
    import {
        getSignature,
        wx_appid,
        wx_noncestr,
        wx_timestamp,
    } from "../utils/function.js";
    import imglink from "../assets/img_logo.jpg";

    export default {
        data() {
            return {
                maskShow: false,
                videoUrl: "",
                openId: this.$route.query.openId,
                unionid: this.$route.query.unionid,
                list: [],
            }
        },

        components: {
            TopBar
        },
        methods: {
            delVideo(file_id,video_id) {
                Dialog.confirm({
                    title: '标题',
                    message: '确认删除该条视频吗?',
                }).then(() => {
                        delvideo({ openId: this.openId, unionid: this.unionid, fileid: file_id }).then(res => {
                            if (res.code == 0) {
                                this.list = this.list.filter(item=>{
                                    return item.video_id !=video_id;
                                })

                                Toast.success("删除成功")
                            } else {
                                Toast.fail("删除失败")
                            }
                        })
                    })
            },
            downloadVideo() {

            },
            playVideo(url) {
                
                this.videoUrl = url;
				window.location.href=this.videoUrl;
                // this.maskShow = !this.maskShow;
                console.log(this.videoUrl);
            },
            closeVideo() {
                this.maskShow = !this.maskShow;
                this.videoUrl = "";
            }
        },
        mounted() {
            //获取签名
            getStr().then(res => {
                console.log(res.data);
                const jsapi_ticket = res.data;
                let signParams = {
                    jsapi_ticket: jsapi_ticket,
                    noncestr: wx_noncestr,
                    timestamp: wx_timestamp,
                    url: window.location.href,
                }
                const signature = getSignature(signParams);
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: wx_appid, // 必填，公众号的唯一标识
                    timestamp: wx_timestamp, // 必填，生成签名的时间戳
                    nonceStr: wx_noncestr, // 必填，生成签名的随机串
                    signature: signature, // 必填，签名
                    jsApiList: ["updateAppMessageShareData", "hideMenuItems"] // 必填，需要使用的JS接口列表
                });

                wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                    wx.updateAppMessageShareData({
                        title: '同联共证', // 分享标题
                        desc: '同联共证', // 分享描述
                        link: 'http://gz.ywkj.online/gz/GzgjController/gz_loadCode', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imglink, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:openWithQQBrowser"] // 要显示的菜单项，所有menu项见附录3
                    });
                });

            })




            getvideolist({ openId: this.openId, unionid: this.unionid }).then(res => {
                console.log(res);
                this.list = res.data
            })

        }

    }

</script>
<style>

</style>