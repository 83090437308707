<template>
  <div>
    <div class="L-guoli-topbar L-bg-maincolor">
      <van-row type="flex" align="center" >
        <van-col :span="8">
          <slot name="left"></slot>
        </van-col>
        <van-col class="L-text-align-center" :span="8">
          <span class="L-bgtext">{{$route.meta.title}}</span>
        </van-col>
        <van-col :span="8" class="L-text-align-right">
          <slot name="right"></slot>
        </van-col>
      </van-row>
    </div>
    <div class="L-margin-top-45"></div>
  </div>
</template>

<script>
export default {
  
  props: {
    msg: String
  },
  mounted(){
    console.log(this.$route.meta.title)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
  

</style>
