<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <br />
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">{{infoObj.law_firm_name}}</span> </div>
            <div class="L-text L-infocolor" style="line-height: 22px;">
                <i class="iconfont icon-yinhao"></i>
                {{infoObj.synopsis}}
                <i class="iconfont icon-yinhao-copy-copy"></i>
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">律师团队</span></div>
            <br />
            <div class="L-guoli-flex-row" >
                <div class="L-guoli-people-box" v-for="item in lawyerList" :key="item.lawyer_id" @click="$router.push({path:'lawyerInfo',query:{...item}})">
                    <div class="img-box">
                        <img :src="item.logo" style="width: 100px;height: 140px;">
                    </div>
                    <div class="L-margin-top-10 L-text ">{{item.lawyer_name}}</div>
                </div>
                
            </div>
            <br />

        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">联系电话</span></div>
            <van-row>
                <van-col :span="12" class="L-text-align-center">
                    <div><i class="iconfont icon-zuoji L-h1" style="color:#fec562"></i></div>
                    <div class="L-text L-margin-top-10"><span>{{infoObj.law_firm_tel}}</span></div>
                </van-col>
                <van-col :span="12" class="L-text-align-center">

                    <div><i class="iconfont icon-shouji3 L-h1 " style="color:#5ab8a6"></i></div>
                    <div class="L-text L-margin-top-10"><span>{{infoObj.law_firm_phone}}</span></div>
                </van-col>
            </van-row>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        <div class="L-row-95 L-margin-auto-10">
            
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">Hi,我们在这</span></div>
            <br />
            <div class="L-text L-infocolor L-text-align-center" style="line-height: 22px;">
                <i class="iconfont icon-yinhao"></i>
                {{infoObj.law_firm_address}}
                <i class="iconfont icon-yinhao-copy-copy"></i>
            </div>
            <br />
        </div>
        <div style="background-color: #f0f0f0;height: 15px;"></div>

    </div>
</template>
<script>
    import TopBar from "../components/TopBar";
    import {getLawyerTeam} from "../api";
    export default {
        data(){
            return{
                infoObj:{},
                lawyerList:[],
            }

        },
        components: {
            TopBar,

        },
        created(){
            this.infoObj = {...this.$route.query}
            console.log(this.infoObj);
            getLawyerTeam({id:this.infoObj.law_firm_id}).then(res=>{
                console.log(res.data);
                this.lawyerList = res.data;
            })
        }

    }

</script>
<style>

</style>