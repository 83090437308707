<template>
	<div>
		<TopBar>
			<template #left>
				<i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
			</template>
		</TopBar>
		<van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-for="item in list"
			:key="item.log_date">
			<van-col :span="3" class="L-text-align-center">
				<van-icon name="service" size="26" color="#F39C12" />
				<!-- <i class="iconfont icon-shipin L-h4 L-maincolor"></i> -->
			</van-col>
			<van-col :span="18">
				<div class="L-text L-color-777" @click="playAudio(item.url)">{{item.log_date}}-{{item.name}}</div>
			</van-col>
			<van-col :span="3" class="L-text-align-center">
				<van-icon name="play-circle-o" color="#1ABC9C" size="20" @click="playAudio(item.url)" />

				<!-- <i class="iconfont icon-shipin1 L-h5" style="color:#F39C12" ></i> -->
				<!-- <i class="iconfont icon-xiazai11 L-margin-left-10 L-h5" style="color:#1ABC9C"></i> -->

			</van-col>
		</van-row>
		<div class="L-mask L-text-align-center" v-show="maskShow" @click="closeAudio"
			style="z-index:10000000;background-color:#000;">

			<audio :src="audioUrl" controls="controls" style="margin-top: 145px;" autoplay="autoplay">
				your browser does not support the video tag
			</audio>
		</div>



	</div>

</template>
<script>
	import TopBar from "../components/TopBar";
	import {
		getVoiceLog,
		getStr
	} from "../api";
	import wx from 'weixin-js-sdk';
	import {
		getSignature,
		wx_appid,
		wx_noncestr,
		wx_timestamp,
	} from "../utils/function.js";
	import imglink from "../assets/img_logo.jpg";
	// import { Dialog, Toast } from 'vant';

	export default {
		data() {
			return {
				maskShow: false,
				audioUrl: "",
				openId: this.$route.query.openId,
				unionid: this.$route.query.unionid,
				list: [],
				tel: this.$route.query.mobile,

			}
		},

		components: {
			TopBar
		},
		methods: {
			closeAudio() {
				this.maskShow = !this.maskShow;
				this.audioUrl = "";
			},


			playAudio(url) {

				this.audioUrl = url;
				// window.location.href = this.audioUrl;
				this.maskShow = !this.maskShow;
				// console.log(this.audioUrl);
			},

		},
		mounted() {
			//获取签名
			getStr().then(res => {
				console.log(res.data);
				const jsapi_ticket = res.data;
				let signParams = {
					jsapi_ticket: jsapi_ticket,
					noncestr: wx_noncestr,
					timestamp: wx_timestamp,
					url: window.location.href,
				}
				const signature = getSignature(signParams);
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: wx_appid, // 必填，公众号的唯一标识
					timestamp: wx_timestamp, // 必填，生成签名的时间戳
					nonceStr: wx_noncestr, // 必填，生成签名的随机串
					signature: signature, // 必填，签名
					jsApiList: ["updateAppMessageShareData", "hideMenuItems"] // 必填，需要使用的JS接口列表
				});

				wx.ready(function() { //需在用户可能点击分享按钮前就先调用
					wx.updateAppMessageShareData({
						title: '同联共证', // 分享标题
						desc: '同联共证', // 分享描述
						link: 'http://gz.ywkj.online/gz/GzgjController/gz_loadCode', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: imglink, // 分享图标
						success: function() {
							// 设置成功
						}
					});
					wx.hideMenuItems({
						menuList: ["menuItem:copyUrl",
							"menuItem:openWithQQBrowser"] // 要显示的菜单项，所有menu项见附录3
					});
				});

			})



			getVoiceLog({
				tel: this.tel
			}).then(res => {
				console.log(res);
				this.list = res.data;
			});



		}

	}
</script>
<style>

</style>
