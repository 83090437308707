<template>
    <div>
        <TopBar></TopBar>
    
        <van-tabs v-model="type" color="#ec532d" @change="handleChange">
			<van-tab title="司法鉴定中心" name="司法鉴定中心"></van-tab>
            <van-tab title="司法鉴定人员" name="司法鉴定人员"></van-tab>
            
        </van-tabs>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        

        <div v-show="type==='司法鉴定人员'">
            <div v-for="item in list" :key="item.dept_id">
                <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" @click="$router.push({path:'identifierInfo',query:{...item}})">
                    <van-col :span="6" class="L-text-align-center">
						<img :src="item.logo===null ? avatarDefault : item.logo" style="width: 100%;">
                        <!-- <i class="iconfont icon-chaxun-sifajiandingyuan L-h1 L-maincolor"></i> -->
                    </van-col>
                    <van-col :span="14">
                        <div class="L-bgtext L-maincolor">
                            {{item.judicial_appraiser_name}}
                           
                        </div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">
                            {{item.jg_name}}
                        </div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">
                            {{item.judicial_appraiser_sitename}}
                        </div>
                    </van-col>
                    <van-col :span="4" class="L-text-align-center">
                        <van-icon name="arrow" class="L-infocolor L-h5" />
                    </van-col>
                </van-row>
            </div>
        </div>

        <div v-show="type==='司法鉴定中心'">
            <div v-for="item in jdzxList" :key="item.law_firm_id">
                <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" @click="$router.push({path:'identificationCenterInfo',query:{...item}})">
                    <van-col :span="6" class="L-text-align-center">
						
                        <i class="iconfont icon-notarial-office L-h1 L-maincolor"></i>
                    </van-col>
                    <van-col :span="15">
                        <div class="L-bgtext L-maincolor">{{item.judicial_name}}</div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">地址:{{item.address}}</div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">联系电话:{{item.tel}}</div>
                    </van-col>
                    <van-col :span="3" class="L-text-align-center">
                        <van-icon name="arrow" class="L-h5 L-infocolor" />
                        <!-- <i class="iconfont icon-xiayibu L-h5 L-infocolor"></i> -->
                    </van-col>
                </van-row>
            </div>
           
        </div>
    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
	import avatarDefault from "../assets/img_avatar111.jpg"

    import {getJAppraiser,getJAppraisal} from "../api";
    export default {
        data() {
            return {
                type:sessionStorage.getItem("jdListType") ||  "司法鉴定中心",
                code:this.$route.query.code || 1,
                list:[],
                jdzxList:[],
				avatarDefault:avatarDefault
            }
        },
        components:{
            TopBar
        },
        methods:{
            handleChange(name){
                this.type=name;
                sessionStorage.setItem("jdListType",name);
            }
        },
        created(){
            // this.code = ;
            getJAppraiser({code:this.code}).then(res=>{
                this.list = res.data;
            })
            getJAppraisal({code:this.code}).then(res=>{
                console.log(res.data);
                this.jdzxList = res.data;
            })
        }
    }

</script>
<style>

</style>