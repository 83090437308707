<template>
    <div>
        <vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
            :bgColor.sync="bgColor" />
        <button @click="handleReset">清空画板</button>
        <button @click="handleGenerate">生成图片</button>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                lineWidth: 6,
                lineColor: '#000000',
                bgColor: '',
                resultImg: '',
                isCrop: false
            }
        },
        methods: {
            handleReset() {
                this.$refs.esign.reset()
            },
            handleGenerate() {
                this.$refs.esign.generate().then(res => {
                    this.resultImg = res
                    console.log(res);
                }).catch(err => {
                    alert(err) // 画布没有签字时会执行这里 'Not Signned'
                })
            }
        }
    }
</script>

<style scoped>
    .sign-canvas {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 20px 30px;
    }

    .sign-canvas canvas {
        background-color: #e0e3e5;
    }

    .sign-btn {
        display: flex;
        margin: 20px 0;
    }

    .sign-btn div {
        width: 175px;
        text-align: center;
        height: 70px;
        line-height: 70px;
        color: #FFFFFF;
    }

    .sign-btn div:active {
        background-color: #CCCCCC;
        color: #333333;
    }

    .sign-btn .clear {
        background-color: #FF8F58;
    }

    .sign-btn .save {
        background-color: #0599D7;
    }
</style>