import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import router from "./router/index.js";
import axios from 'axios';
import vueEsign from 'vue-esign'
import wx from 'weixin-js-sdk'

Vue.use(vueEsign)
import 'vant/lib/index.css';
import './iconfont/iconfont.css';
import './less/L.less';
import './less/common.less';



Vue.use(Vant);
Vue.config.productionTip = false;



Vue.prototype.$axios = axios;
Vue.prototype.wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
	router,
	axios,
  render: h => h(App),
}).$mount('#app')
