<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <div class="L-guoli-avater-container">
            <div> <img :src="infoObj.logo"></div>
            <div class="L-margin-top-10">
                <span class="L-bgtext L-maincolor">{{infoObj.lawyer_name}}</span>
            </div>
        </div>
        <div class="L-row-95 L-margin-auto-10">

            <div class="L-guoli-title-row "> <span class="L-margin-left-10">事务所</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
                {{infoObj.law_firm_name}}
            </div>
            <br/>
        </div>
        <!-- <div style="background-color: #f0f0f0;height: 15px;"></div> -->
        <div class="L-row-95 L-margin-auto-10">
            <div class="L-guoli-title-row "> <span class="L-margin-left-10">律师简介</span></div>

            <div class="L-text L-infocolor L-text-align-left" style="line-height: 22px;">
                <i class="iconfont icon-yinhao"></i>
                {{infoObj.synopsis}}
                <i class="iconfont icon-yinhao-copy-copy"></i>
            </div>

        </div>





    </div>
</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
        data(){
            return{
                infoObj:{}
            }

        },
        components: {
            TopBar,
        },
        created(){
            this.infoObj = {...this.$route.query}
            console.log(this.infoObj);
        }

    }

</script>
<style>

</style>