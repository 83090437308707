<template>
    <div>
        <TopBar />
        <br>
        <div class="L-margin-top-60 L-text-align-center">
            <b class="L-h5 L-maincolor">长按识别二维码</b>
        </div>
        <div class="L-margin-top-60 L-text-align-center">
            <img src="../assets/img_qcode.jpg" style="width: 200px;" />
        </div>

       

    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
        data() {
            return {
                ID: ""
            }
        },

        components: {
            TopBar
        },
        methods: {
            close() {
                window.WeixinJSBridge.call('closeWindow');
            }

        },

    }

</script>
<style>
    .codeBtn {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }
</style>