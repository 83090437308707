<template>
    <div>
        <van-row class="L-guoli-search-bar">
            <van-col :span="1"></van-col>
            <van-col :span="22">
                <van-search v-model="keyword" placeholder="请输入搜索关键词" background="#ec532d"  @search="onSearch" />
            </van-col>
            <van-col :span="1"></van-col>
        </van-row>
        <div style="height: 55px;"></div>

        <van-grid :column-num="3">

            <van-grid-item @click="toSonList(1,'委托')">
                <div><i class="iconfont icon-weituoxuanzhong L-h3" style="color:#1ABC9C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">委托</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(2,'声明')">
                <div><i class="iconfont icon-shengming L-h3" style="color:#F1C40F"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">声明</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(3,'赠与(单方)')">
                <div><i class="iconfont icon-zengyu L-h4" style="color:#2ECC71"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">赠与(单方)</span></div>
            </van-grid-item>



            <van-grid-item @click="toSonList(4,'受赠')">
                <div><i class="iconfont icon-jicheng L-h3" style="color:#E67E22"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">受赠</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(5,'遗嘱')">
                <div><i class="iconfont icon-zhengju2 L-h4" style="color:#3498DB"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">遗嘱</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(6,'保证')">
                <div><i class="iconfont icon-baozheng L-h4" style="color:#E74C3C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">保证</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(7,'公司章程')">
                <div><i class="iconfont icon-gongsizhangcheng L-h3" style="color:#9B59B6"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">公司章程</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(8,'认领亲子')">
                <div><i class="iconfont icon-qinzi L-h3" style="color:#16A085"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">认领亲子</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(9,'出生')">
                <div><i class="iconfont icon-chusheng L-h3" style="color:#F39C12"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">出生</span></div>
            </van-grid-item>



            <van-grid-item @click="toSonList(10,'生存')">
                <div><i class="iconfont icon-chushengzheng L-h4" style="color:#27AE60"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">生存</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(11,'死亡')">
                <div><i class="iconfont icon-bqklt L-h3" style="color:#D35400"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">死亡</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(12,'身份')">
                <div><i class="iconfont icon-shenfen L-h4" style="color:#2980B9"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">身份</span></div>
            </van-grid-item>



            <van-grid-item @click="toSonList(13,'曾用名(又名、别名)')">
                <div><i class="iconfont icon-mingzi L-h3" style="color:#C0392B"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">曾用名(又名、别名)</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(14,'住所地(居住地)')">
                <div><i class="iconfont icon-lvshishiwusuo L-h3" style="color:#8E44AD"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">住所地(居住地)</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(15,'学历')">
                <div><i class="iconfont icon-xueli L-h4" style="color:#1ABC9C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">学历</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(16,'学位')">
                <div><i class="iconfont icon-xueweizheng L-h3" style="color:#F1C40F"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">学位</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(17,'经历')">
                <div><i class="iconfont icon-jingli1 L-h2" style="color:#2ECC71"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">经历</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(18,'职务(职称)')">
                <div><i class="iconfont icon-tel L-h3" style="color:#E67E22"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">职务(职称)</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(19,'资格')">
                <div><i class="iconfont icon-zige L-h3" style="color:#3498DB"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">资格</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(20,'无（有）犯罪记录')">
                <div><i class="iconfont icon-kaijuyouwuweifafanzuijilu L-h3" style="color:#E74C3C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">无（有）犯罪记录</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(21,'婚姻状况')">
                <div><i class="iconfont icon-hunyin1 L-h3" style="color:#9B59B6"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 婚姻状况</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(22,'亲属关系')">
                <div><i class="iconfont icon-qinshu L-h3" style="color:#BDC3C7"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">亲属关系</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(23,'收养')">
                <div><i class="iconfont icon-shengyushouyang L-h2" style="color:#34495E"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 收养</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(24,'抚养事实')">
                <div><i class="iconfont icon-zongfuyangbi L-h3" style="color:#16A085"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 抚养事实</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(25,'财产权')">
                <div><i class="iconfont icon-caichanxingquanli L-h3" style="color:#F39C12"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 财产权</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(26,'收入状况')">
                <div><i class="iconfont icon-xinlingshouyewuicon- L-h3" style="color:#27AE60"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 收入状况</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(27,'纳税状况')">
                <div><i class="iconfont icon-caichan L-h4" style="color:#D35400"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 纳税状况</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(28,'票据拒绝')">
                <div><i class="iconfont icon-iconfontpiaoju01 L-h3" style="color:#2980B9"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 票据拒绝</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(29,'选票')">
                <div><i class="iconfont icon-xuanju L-h3" style="color:#C0392B"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 选票</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(30,'指纹（印鉴）')">
                <div><i class="iconfont icon-icon_zhiwen L-h4" style="color:#8E44AD"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">  指纹（印鉴）</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(31,'不可抗力（意外事件）')">
                <div><i class="iconfont icon-bukekanglituidingbao L-h3" style="color:#95A5A6"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 不可抗力（意外事件）</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(32,'查无档案记载')">
                <div><i class="iconfont icon-dangan L-h3" style="color:#1ABC9C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 查无档案记载</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(33,'证书(执照)')">
                <div><i class="iconfont icon-zhengshu1 L-h3" style="color:#F1C40F"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">  证书(执照)</span></div>
            </van-grid-item>




            <van-grid-item @click="toSonList(34,'公证提存')">
                <div><i class="iconfont icon-qianming L-h3" style="color:#2ECC71"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 文书上的签名（印鉴）</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(35,'文本相符')">
                <div><i class="iconfont icon-wenben L-h3" style="color:#1ABC9C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 文本相符</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(36,'保全证据')">
                <div><i class="iconfont icon-zhengju L-h3" style="color:#E67E22"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">  保全证据</span></div>
            </van-grid-item>



            <van-grid-item @click="toSonList(37,'现场监督')">
                <div><i class="iconfont icon-xianchangjiancha L-h3" style="color:#3498DB"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 现场监督</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(38,'合同（协议）')">
                <div><i class="iconfont icon-hetong2 L-h3" style="color:#E74C3C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 合同（协议）</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(39,' 继承（受遗赠）')">
                <div><i class="iconfont icon-jiegou L-h3" style="color:#9B59B6"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777">  继承（受遗赠）</span></div>
            </van-grid-item>


            <van-grid-item @click="toSonList(40,'具有强制执行效力的债权文书公证书及执行证书')">
                <div><i class="iconfont icon-baozheng1 L-h3" style="color:#1ABC9C"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 具有强制执行效力的债权文书公证书及执行证书</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(41,'公证抵押登记')">
                <div><i class="iconfont icon-ziyuan L-h3" style="color:#E67E22"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 公证抵押登记</span></div>
            </van-grid-item>
            <van-grid-item @click="toSonList(42,'公证提存')">
                <div><i class="iconfont icon-drxx20 L-h3" style="color:#3498DB"></i></div>
                <div class="L-margin-top-10 L-smtext-align-center"><span class="L-smtext L-color-777"> 公证提存</span></div>
            </van-grid-item>
           
        </van-grid>

    </div>
</template>
<script>
    export default{
        data(){
            return{
                keyword:"",
                code:this.$route.query.code || 1,
            }
        },
        methods:{
            onSearch(keyword){
                this.$router.push({path:"searchList",query:{keyword:keyword,code:this.code}});
            },
            toSonList(id,title){
                // console.log($e);
                this.$router.push({path:"searchList",query:{metarial_code:id,code:this.code,title:title}});
            }
        },
        
    }

</script>
<style>


</style>