<template>
    <div>
        <div class="L-guoli-topbar L-bg-maincolor">
            <van-row type="flex" align="center">
                <van-col :span="4">
                    <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
                </van-col>
                <van-col class="L-text-align-center L-nowrap-row" :span="16">
                    <span class="L-bgtext">{{title}}</span>
                </van-col>
                <van-col :span="4" class="L-text-align-right">
                    
                </van-col>
            </van-row>
        </div>
        <div class="L-margin-top-45"></div>

        <div v-for="(item,index) in list" :key="index">
            <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-if="item.status==0"
                @click="$router.push({path:'searchDetail',query:{...item}})">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-dangan L-h4 L-maincolor"></i>
                </van-col>
                <van-col :span="16">
                    <div class="L-text L-color-777">{{item.material_code}} {{item.material_name}}</div>
                </van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>


            <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-if="item.status==1"
                @click="$router.push({path:'searchSecondList',query:{metarial_code:item.material_code,code:code,title:item.material_name}})">
                <van-col :span="4" class="L-text-align-center">
                    <i class="iconfont icon-dangan L-h4 L-maincolor"></i>
                </van-col>
                <van-col :span="16">
                    <div class="L-text L-color-777">{{item.material_code}} {{item.material_name}}</div>
                </van-col>
                <van-col :span="4" class="L-text-align-center">
                    <van-icon name="arrow" class="L-infocolor" />
                </van-col>
            </van-row>

        </div>


    </div>

</template>
<script>
    // import TopBar from "../components/TopBar";
    import { getBMaterial, getSearchMaterial } from '../api'
    export default {
        data() {
            return {
                list: [],
                title:"",
                code:this.$route.query.code || 1,
            }
        },


        components: {
            // TopBar
        },
        methods:{
            // toDetail(){
            //     $router.push({path:'searchSecondList',query:{metarial_code:item.material_code,code:code}})
            // }
            
        },
        created() {
            if (this.$route.query.keyword) {
                const params = {
                    code: this.code,
                    name: this.$route.query.keyword,
                }
                this.title = `搜索'${this.$route.query.keyword}'的结果`;
                getSearchMaterial(params).then(res => {
                    console.log(res);
                    this.list = res.data;
                })
            }
            
            if (this.$route.query.metarial_code) {
                const params = {
                    code: this.code,
                    metarial_code: this.$route.query.metarial_code,
                }
                this.title = this.$route.query.metarial_code+". "+this.$route.query.title;
                getBMaterial(params).then(res => {
                    console.log(res);
                    this.list = res.data;


                })

            }


        }

    }

</script>
<style>

</style>