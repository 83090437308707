<template>
    <div>
        <div class="L-guoli-topbar L-bg-maincolor">
            <van-row type="flex" align="center">
                <van-col :span="4">
                    <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
                </van-col>
                <van-col class="L-text-align-center L-nowrap-row" :span="16">
                    <span class="L-bgtext">{{$route.query.metarial_code}} {{$route.query.title}}</span>
                </van-col>
                <van-col :span="4" class="L-text-align-right">
                    
                </van-col>
            </van-row>
        </div>
        <div class="L-margin-top-45"></div>
        <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" v-for="(item,index) in list"
            :key="index" @click="$router.push({path:'searchDetail',query:{...item}})">
            <van-col :span="4" class="L-text-align-center">
                <i class="iconfont icon-dangan L-h4 L-maincolor"></i>
            </van-col>
            <van-col :span="16">
                <div class="L-text L-color-777">{{item.material_code}} {{item.material_name}}</div>
            </van-col>
            <van-col :span="4" class="L-text-align-center">
                <van-icon name="arrow" class="L-infocolor" />
            </van-col>
        </van-row>

    </div>

</template>
<script>
    // import TopBar from "../components/TopBar";
    import { getBMaterial} from '../api'
    export default {
        data() {
            return {
                list: [],
                title:this.$route.query.title || '证明材料',
            }
        },

        // components: {
        //     TopBar
        // },
        created() {
           

            if (this.$route.query.metarial_code) {
                const params = {
                    code: 1,
                    metarial_code: this.$route.query.metarial_code,
                }
                getBMaterial(params).then(res => {
                    console.log(res);
                    this.list = res.data;


                })

            }


        }

    }

</script>
<style>

</style>