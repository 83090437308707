<template>
    <div>
        <TopBar></TopBar>
        <br>
        <div class="L-text-align-center L-margin-top-40">
            <img src="../assets/img_register_fail.png" style="width: 80%;" />
        </div>
        <div class="L-text-align-center L-bgtext L-infocolor">注册失败!</div>
        <br/>
        <div class="L-text-align-center">
            <van-button type="primary" style="width: 50%;" @click="close" >关闭页面</van-button>
        </div>
       
    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
       
        components: {
            TopBar
        },
        methods:{
            close(){
               window.WeixinJSBridge.call('closeWindow');
            }

        },
       
    }

</script>
<style>
    .codeBtn{
        position: absolute;
        bottom:10px;
        right:20px;
    }

</style>