<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <br>
        <div class="L-text-align-center L-margin-top-40">
            <img src="../assets/img_face_fail3.png" style="width: 40%;" />
        </div>
        <br/>
        <br/>
        <div class="L-text-align-center L-bgtext L-infocolor">人脸识别失败!</div>
        <br/>
        <br/>
        <div class="L-text-align-center">
            <van-button type="primary" style="width: 50%;" @click="close" >关闭页面</van-button>
        </div>
       

    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    export default {
        

        components: {
            TopBar
        },
        methods:{
            close(){
               window.WeixinJSBridge.call('closeWindow');
            }

        },
       

    }

</script>
<style>
    .codeBtn{
        position: absolute;
        bottom:10px;
        right:20px;
    }

</style>