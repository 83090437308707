<template>
    <div>
        <TopBar></TopBar>
    
        <van-tabs v-model="type" color="#ec532d" @change="handleChange">
			<van-tab title="律师事务所" name="律师事务所"></van-tab>
            <van-tab title="律师信息" name="律师信息"></van-tab>
            
        </van-tabs>
        <div style="background-color: #f0f0f0;height: 15px;"></div>
        

        <div v-show="type==='律师信息'">
            <div v-for="item in list" :key="item.lawyer_id">
                <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" @click="$router.push({path:'lawyerInfo',query:{...item}})">
                    <van-col :span="8" class="L-text-align-center">
                        <img :src="item.logo" style="width:80px;">
                    </van-col>
                    <van-col :span="14">
                        <div class="L-bgtext L-maincolor">
                            {{item.lawyer_name}}
                            <!-- <span class="L-smtext L-margin-left-10">10年工作经验</span> -->
                        </div>
                        <div class="L-text L-infocolor L-margin-top-5">
                            {{item.law_firm_name}}
                            <!-- 长春金城律师事务所 -->
                        </div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">
                            {{item.synopsis}}
                            <!-- 擅长:民事纠纷,离婚协议等等.... -->
                        </div>
                    </van-col>
                    <van-col :span="2" class="L-text-align-center">
                        <van-icon name="arrow" class="L-infocolor L-h5" />
                    </van-col>
                </van-row>
            </div>
            
            
        </div>
        <div v-show="type==='律师事务所'">
            <div v-for="item in lawFirmList" :key="item.law_firm_id">
                <van-row type="flex" align="center" class="L-margin-top-10 L-guoli-list" @click="$router.push({path:'officeDetail',query:{...item}})">
                    <van-col :span="6" class="L-text-align-center">
                        <i class="iconfont icon-xiezilou2 L-h1 L-maincolor"></i>
                    </van-col>
                    <van-col :span="15">
                        <div class="L-bgtext L-maincolor">{{item.law_firm_name}}</div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">地址:{{item.law_firm_address}}</div>
                        <div class="L-text L-infocolor L-margin-top-5 L-nowrap-row">联系电话:{{item.law_firm_tel}}</div>
                    </van-col>
                    <van-col :span="3" class="L-text-align-center">
                        <van-icon name="arrow" class="L-h5 L-infocolor" />
                        <!-- <i class="iconfont icon-xiayibu L-h5 L-infocolor"></i> -->
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>

</template>
<script>
    import TopBar from "../components/TopBar";

    import {lawyer,lawFirm} from "../api";
    export default {
        data() {
            return {
                type:sessionStorage.getItem("listType") ||  "律师事务所",
                code:this.$route.query.code || 1,
                list:[],
                lawFirmList:[],
            }
        },
        components:{
            TopBar
        },
        methods:{
            handleChange(name){
                this.type=name;
                sessionStorage.setItem("listType",name);
            }
        },
        created(){
            // this.code = this.$route.query.code || 1;
            lawyer({code:this.code}).then(res=>{
                this.list = res.data;
            })
            lawFirm({code:this.code}).then(res=>{
                console.log(res.data);
                this.lawFirmList = res.data;
            })
        }
    }

</script>
<style>

</style>