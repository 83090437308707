import sha1 from "js-sha1";
import axios from "axios";

//按字典顺序排列
function objKeySort(arys) {
	//先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
	var newkey = Object.keys(arys).sort();
	//console.log('newkey='+newkey);
	var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
	for (var i = 0; i < newkey.length; i++) {
		//遍历newkey数组
		newObj[newkey[i]] = arys[newkey[i]];
		//向新创建的对象中按照排好的顺序依次增加键值对

	}
	return newObj; //返回排好序的新对象
}

//按删除空值
function deletedNull(obj) {
	for (var key in obj) {
		if (obj[key] === '') {
			delete obj[key]
		}
	}
	return obj;
}





function getAccessToken(){
	// let access_token = "";

	let wxParams = {
		appid: "wx9f9d60ee7ce5c5e2",
		secret: "7e9ede4ccaa397b9c963af7201dae5c4",
		grant_type: "client_credential"
	};
	let nBaseUrl = "http://" + window.location.hostname + ":" + window.location.port;
	
	// new Promise(()=>{
		
	// })
	axios({
		baseURL: nBaseUrl,
		method: "get",
		url: "wxApi/cgi-bin/token",
		params: wxParams,
	}).then(res => {
		console.log(res.data.access_token);
		
		// return access_token;

	}).catch(error => {
		console.log(error);
	});
	
	



}

let getJsApiTicket = (access_token) => {
	// let jsapi_ticket = "";
	let nBaseUrl = "http://" + window.location.hostname + ":" + window.location.port;
	axios({
		baseURL: nBaseUrl,
		method: "get",
		url: "wxApi/cgi-bin/ticket/getticket",
		params: {
			access_token: access_token,
			type: "jsapi"
		},
	}).then(res => {
		return res.data.ticket;
	}).catch(error => {
		console.log(error);
	});
}

let getSignature = (params) => {
	params = objKeySort(params); //字典顺序排列
	var params_str = "";
	for (let key in params) {
		params_str += key + "=" + params[key] + "&";
	}
	params_str = params_str.substr(0,params_str.length-1)
	console.log(params_str);
	return sha1(params_str); 
}


const wx_appid = "wx2c9327c6798131d5";
const wx_noncestr = "glz4220969";
const wx_timestamp = Date.parse(new Date()) / 1000;

export {
	wx_appid,
	wx_noncestr,
	wx_timestamp,
	getSignature,
	deletedNull,
	getAccessToken,
	getJsApiTicket

}
