<template>
    <div>
        <TopBar>
            <template #left>
                <i class="iconfont icon-fanhui L-whitecolor L-margin-left-10" @click="$router.go(-1)"></i>
            </template>
        </TopBar>
        <div class="L-margin-top-40 L-text-align-center">
            <img src="../assets/img_sfz3.png" style="width: 200px;" />
        </div>

        <van-cell-group>
            <van-field v-model="certificatecode" label="对方身份证号" placeholder="请输入对方身份证号" />
        </van-cell-group>
        <div style="margin: 36px;">
            <van-button round block type="info" color="#ec532d" @click="onSubmit">提交</van-button>
        </div>

    </div>

</template>
<script>
    import TopBar from "../components/TopBar";
    import { fromuservideo } from "../api"
    import { Toast } from "vant"

    export default {
        data() {
            return {
                certificatecode: "",
                openId: this.$route.query.openid || '',
                unionid: this.$route.query.unionid || '',
            }
        },
        components: {
            TopBar
        },
        methods: {
            isCardNo(card) {
                // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X 
                var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (reg.test(card) === false) {
                    // alert("身份证输入不合法");
                    return false;
                }
            },
            onSubmit() {
                if(this.isCardNo(this.certificatecode)===false){
                    Toast("身份证输入不合法");
                    return
                }
                const params = {
                    certificatecode: this.certificatecode,
                    openId: this.openId,
                    unionid: this.unionid,
                }
                console.log(params);
                fromuservideo(params).then(res => {
                    
                    if (res.code == 0) {
                        Toast(res.msg)

                        this.$router.push("qrcode");

                    }
                })

            },
			
        },
		

    }

</script>
<style>
    .codeBtn {
        position: absolute;
        bottom: 10px;
        right: 20px;
    }
</style>