import request from "../utils/request.js"


//公证处简介
export function notarialOffice(params) {
	return request({
		url: '/buddy/notarialOffice',
		method: 'get',
		params: params
	})
}



//公证员列表
export function notary(params) {
	return request({
		url: '/buddy/notary',
		method: 'get',
		params: params
	})
}

//律师列表
export function lawyer(params) {
	return request({
		url: '/buddy/lawyer',
		method: 'get',
		params: params
	})
}

//律师事务所列表
export function lawFirm(params) {
	return request({
		url: '/buddy/lawFirm',
		method: 'get',
		params: params
	})
}

//事务所律师团队
export function getLawyerTeam(params) {
	return request({
		url: '/buddy/getLawyerTeam',
		method: 'get',
		params: params
	})
}

//查询点击材料列表
export function getBMaterial(params) {
	return request({
		url: '/buddy/getBMaterial',
		method: 'get',
		params: params
	})
}

//搜索材料的列表
export function getSearchMaterial(params) {
	return request({
		url: '/buddy/getSearchMaterial',
		method: 'get',
		params: params
	})
}

//鉴定人员
export function getJAppraiser(params) {
	return request({
		url: '/buddy/getJAppraiser',
		method: 'get',
		params: params
	})
}



//鉴定中心
export function getJAppraisal(params) {
	return request({
		url: '/buddy/getJAppraisal',
		method: 'get',
		params: params
	})
}

//发送验证码
export function Mobile_message(params) {
	return request({
		url: '/GzgjController/Mobile_message',
		method: 'get',
		params: params
	})
}

//注册提交
export function user_register(params) {
	return request({
		url: '/GzgjController/user_register',
		method: 'get',
		params: params
	})
}


//我的
export function getuserurl(params) {
	return request({
		url: '/GzgjController/getuserurl',
		method: 'get',
		params: params
	})
}

//发起视频
export function fromuservideo(params) {
	return request({
		url: '/GzgjController/fromuservideo',
		method: 'get',
		params: params
	})
}

//视频列表
export function getvideolist(params) {
	return request({
		url: '/GzgjController/getvideolist',
		method: 'get',
		params: params
	})
}
//删除视频
export function delvideo(params) {
	return request({
		url: '/GzgjController/delvideo',
		method: 'get',
		params: params
	})
}


//公证员list
export function gzlist(params) {
	return request({
		url: '/GzgjController/gzlist',
		method: 'get',
		params: params
	})
}

//和公证员对话
export function ksdhtd(params) {
	return request({
		url: '/GzgjController/ksdhtd',
		method: 'get',
		params: params
	})
}

//通话录音
export function getVoiceLog(params) {
	return request({
		url: '/buddy/getVoiceLog',
		method: 'get',
		params: params
	})
}


//获取电话号码
export function getVirtualNumber(params) {
	return request({
		url: '/buddy/getVirtualNumber',
		method: 'get',
		params: params
	})
}

//解绑
export function jcbd(params) {
	return request({
		url: '/buddy/jcbd',
		method: 'get',
		params: params
	})
}


//js_ticket
export function getStr(params) {
	return request({
		url: '/GzgjController/getStr',
		method: 'get',
		params: params
	})
}


//获取鉴定中心的鉴定员列表
export function getJAppraiserByID(params) {
	return request({
		url: '/buddy/getJAppraiserByID',
		method: 'get',
		params: params
	})
}


//
export function formmastervideo(params) {
	return request({
		url: '/GzgjController/formmastervideo',
		method: 'get',
		params: params
	})
}

//
export function loadUserFace(params) {
	return request({
		url: '/GzgjController/loadUserFace',
		method: 'get',
		params: params
	})
}

export function jsapisign(params) {
	return request({
		url: '/signService/jsapisign',
		method: 'get',
		params: params
	})
}

export function getFaceUuid(params) {
	return request({
		url: '/GzgjController/getFaceUuid',
		method: 'get',
		params: params
	})
}

export function loadUserData(params) {
	return request({
		url: '/GzgjController/loadUserData',
		method: 'get',
		params: params
	})
}







































